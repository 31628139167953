.description strong {
  @apply font-semibold;
}

.description p:not(:last-child) {
  @apply mb-5 lg:mb-6;
}

.description li {
  @apply py-2 lg:py-2.5;
}

.description li:not(:last-child) {
  @apply mb-1;
}

.description ol {
  @apply list-decimal pl-5;
}

.description ol li {
  @apply pl-1.5;
}

.description ul li {
  @apply relative pl-[26px];
}

.description ul li:before,
.description ul li:after {
  @apply absolute left-0 top-0 origin-[0%_50%] bg-green-accent-two content-[''];
}

.description ul li:before {
  @apply h-0.5 w-2 translate-x-px translate-y-[19px] rotate-45 xl:translate-y-[21px];
}

.description ul li:after {
  @apply h-0.5 w-[13px] translate-x-1.5 translate-y-[24px] -rotate-45 xl:translate-y-[26px];
}

.inactiveSlide ul li:before,
.inactiveSlide ul li:after {
  @apply bg-content-primary-on-light;
}
