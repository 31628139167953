.defaultLayout {
  @apply grid-cols-1 lg:row-span-2;
}

.withIconLayout {
  @apply grid-cols-[auto_auto]
  lg:row-span-3
  lg:grid-cols-1
  2xl:grid-cols-[auto_auto];
}

.withIconLayout > [data-child="title"] {
  @apply max-md:self-end;
}

.withIconLayout > [data-child="body"] {
  @apply 2xl:col-span-2;
}
