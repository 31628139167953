@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*noinspection CssUnresolvedCustomProperty*/
@keyframes slideshow2Cols {
  0% {
    transform: translateX(0);
  }

  /* 📌 1  */
  25% {
    transform: translateX(calc(-1 * var(--col-width)));
  }

  50% {
    transform: translateX(calc(-1 * var(--col-width)));
  }

  /* 📌 2  */
  75% {
    transform: translateX(calc(-2 * var(--col-width)));
  }

  100% {
    transform: translateX(calc(-2 * var(--col-width)));
  }
}

/*noinspection CssUnresolvedCustomProperty*/
@keyframes slideshow3Cols {
  0% {
    transform: translateX(0);
  }

  /* 📌 1  */
  16% {
    transform: translateX(calc(-1 * var(--col-width)));
  }

  33% {
    transform: translateX(calc(-1 * var(--col-width)));
  }

  /* 📌 2  */
  50% {
    transform: translateX(calc(-2 * var(--col-width)));
  }

  66% {
    transform: translateX(calc(-2 * var(--col-width)));
  }

  /* 📌 3  */
  83% {
    transform: translateX(calc(-3 * var(--col-width)));
  }

  100% {
    transform: translateX(calc(-3 * var(--col-width)));
  }
}

.animate3Cols {
  /* keyframesAmount = 7 => time = 6s (which is keyframesAmount - 1) */
  animation: slideshow3Cols 6s ease-in-out forwards;
}

.animate2Cols {
  /* keyframesAmount = 5 => time = 4s (which is keyframesAmount - 1) */
  animation: slideshow2Cols 4s ease-in-out forwards;
}

.fadeOut {
  animation: fadeOut 0.5s ease-in-out forwards;
}
