.bodyText ul {
  padding-left: 16px;
}

.bodyText ul li {
  padding: 10px 0 10px 15px;
}

.bodyText ul li::marker {
  /* encoded tick url ✔️ */
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlBhdGgiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS41MTI4OSA3LjEzMTA1TDExLjg5NjkgMC41TDE0IDIuNjg0NDhMNS41MTI4OSAxMS41TDAgNS43NzM3OUwyLjEwMzEgMy41ODkzMUw1LjUxMjg5IDcuMTMxMDVaIiBmaWxsPSIjMDBDODU5Ii8+Cjwvc3ZnPgo=);
}
