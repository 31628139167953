.defaultFullPageTextModule ol {
  counter-reset: list-number;
}

.defaultFullPageTextModule ol > li::before {
  counter-increment: list-number;
  content: counter(list-number);
}

.tableWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  @apply h-[3px] w-0;
}

.tableWrapper::-webkit-scrollbar-track {
  @apply rounded-full bg-[#E9EEF0];
}

.tableWrapper::-webkit-scrollbar-thumb {
  @apply cursor-pointer rounded-full bg-grey-scale-light-gray;
}

.tableWrapper a {
  @apply text-inherit;
}

.tableWrapper table {
  @apply w-full border-separate border-spacing-0 overflow-hidden rounded-tl-2xl rounded-tr-2xl text-sm leading-5 xl:text-base;
}

.tableWrapper :is(th, td) {
  @apply border-b-2 border-r-2 border-grey-scale-light-gray px-5 py-4 text-content-primary-on-light md:px-6 md:py-[1.125rem] xl:px-9 xl:py-6;
}

.tableWrapper :is(th, td) strong {
  @apply font-semibold;
}

.tableWrapper th {
  @apply text-left font-semibold;
}

.tableWrapper thead th {
  @apply border-b-0 bg-grey-scale-light-gray;
}

.tableWrapper th:not(thead th) {
  @apply bg-[#f7f9fa];
}

.tableWrapper thead th:not(:last-child) {
  @apply border-r-content-primary-on-dark;
}

.tableWrapper table:not(:has(thead)) tr:first-child td,
.tableWrapper table:not(:has(thead)) tr:first-child th {
  @apply border-t-2;
}

.tableWrapper tr td:first-child,
.tableWrapper tr th:first-child {
  @apply border-l-2;
}

.tableWrapper table:not(:has(thead)) tr:first-child td:first-child,
.tableWrapper table:not(:has(thead)) tr:first-child th:first-child {
  @apply rounded-tl-2xl;
}

.tableWrapper table:not(:has(thead)) tr:first-child td:last-child {
  @apply rounded-tr-2xl;
}

.tableWrapper tr:last-child td:first-child,
.tableWrapper tbody tr:last-child th:first-child {
  @apply rounded-bl-2xl;
}

.tableWrapper tr:last-child td:last-child {
  @apply rounded-br-2xl;
}
